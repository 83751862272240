import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
// import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import PaymentsIcon from "@mui/icons-material/Payments";
import CategoryIcon from "@mui/icons-material/Category";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import EventIcon from "@mui/icons-material/Event";
import SettingsIcon from "@mui/icons-material/Settings";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import DevicesIcon from "@mui/icons-material/Devices";
import SystemSecurityUpdateIcon from "@mui/icons-material/SystemSecurityUpdate";
import WebStoriesIcon from "@mui/icons-material/WebStories";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import BackupTableIcon from "@mui/icons-material/BackupTable";
// import SettingsIcon from '@mui/icons-material/Settings';
// import InventoryIcon from '@mui/icons-material/Inventory';

export const navigations = [
  // {
  //   name: "Dashboard",
  //   path: "/dashboard",
  //   icon: <DashboardIcon fontSize="small" />,
  // },
  { label: "Manage", type: "label" },
  { name: "Users", path: "/users", icon: <GroupIcon fontSize="small" /> },
  // { name: 'Nilos', path: '/nilos', icon: <AutoGraphIcon fontSize="small" /> },
  {
    name: "Config",
    path: "/config?data=Levelling",
    icon: <SettingsIcon fontSize="small" />,
  },
  {
    name: "Epochs",
    path: "/epoch",
    icon: <PaymentsIcon fontSize="small" />,
  },
  {
    name: "Devices",
    path: "/devices",
    icon: <AppSettingsAltIcon fontSize="small" />,
  },
  {
    name: "Assets",
    path: "/nilo-assets?data=Nilos",
    icon: <CategoryIcon fontSize="small" />,
  },
  {
    name: "App Update",
    path: "/appupdate",
    icon: <SystemSecurityUpdateIcon fontSize="small" />,
  },
  // {
  //   name: 'Item Manager',
  //   path: '/item-manager',
  //   icon: <CategoryIcon fontSize="small" />
  // },
  // {
  //   name: 'Gear Manager',
  //   path: '/gear-manager',
  //   icon: <SettingsIcon fontSize="small" />
  // },
  // {
  //   name: 'Inventory Management',
  //   path: '/inventory-management',
  //   icon: <InventoryIcon fontSize="small" />
  // },
  { label: "Events", type: "label" },
  {
    name: "Brushes",
    path: "/brushes",
    icon: <EventIcon fontSize="small" />,
  },
  {
    name: "Transactions",
    path: "/transactions",
    icon: <CurrencyExchangeIcon fontSize="18px" />,
  },
  {
    name: "NLT Transfer",
    path: "/NLT-Transfer",
    icon: <MoveDownIcon fontSize="18px" />,
  },
  {
    name: "Firmware Upgrade",
    path: "/firmware-upgrade",
    icon: <DevicesIcon fontSize="18px" />,
  },
  {
    name: "Hp Decay Logs",
    path: "/hp-decay-logs",
    icon: <WebStoriesIcon fontSize="18px" />,
  },
  {
    name: "External Transfers",
    path: "/external-transfers",
    icon: <MoveUpIcon fontSize="18px" />,
  },
  {
    name: "Orders Logs",
    path: "/order-logs",
    icon: <BackupTableIcon fontSize="18px" />,
  },
  // {
  //   name: 'Session/Auth',
  //   icon: 'security',
  //   children: [
  //     { name: 'Sign in', iconText: 'SI', path: '/session/signin' },
  //     { name: 'Sign up', iconText: 'SU', path: '/session/signup' },
  //     {
  //       name: 'Forgot Password',
  //       iconText: 'FP',
  //       path: '/session/forgot-password'
  //     },
  //     { name: 'Error', iconText: '404', path: '/session/404' }
  //   ]
  // },
  // { label: 'Components', type: 'label' },
  // {
  //   name: 'Components',
  //   icon: 'favorite',
  //   badge: { value: '30+', color: 'secondary' },
  //   children: [
  //     { name: 'Auto Complete', path: '/material/autocomplete', iconText: 'A' },
  //     { name: 'Buttons', path: '/material/buttons', iconText: 'B' },
  //     { name: 'Checkbox', path: '/material/checkbox', iconText: 'C' },
  //     { name: 'Dialog', path: '/material/dialog', iconText: 'D' },
  //     {
  //       name: 'Expansion Panel',
  //       path: '/material/expansion-panel',
  //       iconText: 'E'
  //     },
  //     { name: 'Form', path: '/material/form', iconText: 'F' },
  //     { name: 'Icons', path: '/material/icons', iconText: 'I' },
  //     { name: 'Menu', path: '/material/menu', iconText: 'M' },
  //     { name: 'Progress', path: '/material/progress', iconText: 'P' },
  //     { name: 'Radio', path: '/material/radio', iconText: 'R' },
  //     { name: 'Switch', path: '/material/switch', iconText: 'S' },
  //     { name: 'Slider', path: '/material/slider', iconText: 'S' },
  //     { name: 'Snackbar', path: '/material/snackbar', iconText: 'S' },
  //     { name: 'Table', path: '/material/table', iconText: 'T' }
  //   ]
  // },
  // {
  //   name: 'Charts',
  //   icon: 'trending_up',
  //   children: [{ name: 'Echarts', path: '/charts/echarts', iconText: 'E' }]
  // },
  // {
  //   name: 'Documentation',
  //   icon: 'launch',
  //   type: 'extLink',
  //   path: 'http://demos.ui-lib.com/matx-react-doc/'
  // }
];
