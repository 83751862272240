const Layout1Settings = {
   leftSidebar: {
      show: true,
      mode: 'compact', // full, close, compact, mobile,
      theme: 'slateDark1', // View all valid theme colors inside MatxTheme/themeColors.js
      bgimgurl: '/assets/images/sidebar/sidebar-bg-dark.jpg'
   },
   topbar: {
      show: true,
      fixed: true,
      theme: 'whiteBlue' // View all valid theme colors inside MatxTheme/themeColors.js
   }
}

export default Layout1Settings
