import { ThemeProvider, useTheme } from '@mui/material'
import React from 'react'
import useSettings from '../hooks/useSettings'

function SidenavTheme({ children }) {
   const theme = useTheme()
   const { settings } = useSettings()
   const sidenavTheme =
      settings.themes[settings.layout1Settings.leftSidebar.theme] || theme

   return <ThemeProvider theme={sidenavTheme}>{children}</ThemeProvider>
}

export default SidenavTheme
