import { Box, styled } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import useSettings from '../hooks/useSettings'
import { Span } from './Typography'
import niloImg from '../assets/imaegs/svg/nilo-logo.svg'

const BrandRoot = styled(Box)(() => ({
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'space-between',
   padding: '20px 18px 20px 29px'
}))

const StyledSpan = styled(Span)(({ mode }) => ({
   fontSize: 18,
   marginLeft: '.5rem',
   display: mode === 'compact' ? 'none' : 'block'
}))

function Brand({ children }) {
   const { settings } = useSettings()
   const { leftSidebar } = settings.layout1Settings
   const { mode } = leftSidebar
   const navigate = useNavigate()

   return (
      <BrandRoot>
         <Box
            onClick={() => {
               navigate('/users')
            }}
            display="flex"
            alignItems="center"
         >
            <img
               src={niloImg}
               alt="Nilo"
               style={{ width: '30px', height: '30px', objectFit: 'contain' }}
            />
            {/* <MatxLogo /> */}
            <StyledSpan mode={mode} className="sidenavHoverShow">
               Nilo Admin
            </StyledSpan>
         </Box>

         <Box
            className="sidenavHoverShow"
            sx={{ display: mode === 'compact' ? 'none' : 'block' }}
         >
            {children || null}
         </Box>
      </BrandRoot>
   )
}

export default Brand
