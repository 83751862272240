import serverCall from '../serverCall'

const signIn = (credentials) => {
   const encodedEmail = encodeURIComponent(credentials.email);
   const encodedPassword = encodeURIComponent(credentials.password);
   return serverCall.post(
     `/admin-user/signin?email=${encodedEmail}&password=${encodedPassword}`
   );
 };

const signUp = (credentials) => {
   return serverCall.post(
      `/admin-user/signup?email=${credentials.email}&password=${credentials.password}`
   )
}

const AuthenticationService = {
   signIn,
   signUp
}

export default AuthenticationService
