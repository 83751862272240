import React from 'react'

import { Box, CircularProgress, LinearProgress } from '@mui/material'

function SuspenseLoader() {
   return (
      <>
         <LinearProgress
            color="secondary"
            sx={{
               position: 'fixed',
               top: 0,
               left: 0,
               zIndex: 1301,
               width: '100%'
            }}
         />
         <Box
            sx={{ width: '100%', height: '80vh' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
         >
            <CircularProgress size={64} disableShrink thickness={3} />
         </Box>
      </>
   )
}

export default SuspenseLoader
