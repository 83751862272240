/* eslint-disable no-debugger */
import { Hidden, Box, styled, useTheme } from '@mui/material'
import React from 'react'
import { themeShadows } from '../themes/themeColors'
import { sidenavCompactWidth, sideNavWidth } from '../utils/constant'
import { convertHexToRGB } from '../utils/utils'
import useSettings from '../hooks/useSettings'
import Brand from './Brand'
import Sidenav from './Sidenav'

const SidebarNavRoot = styled(Box)(({ theme, width, primarybg, bgimgurl }) => ({
   position: 'fixed',
   top: 0,
   left: 0,
   height: '100vh',
   width,
   boxShadow: themeShadows[8],
   backgroundRepeat: 'no-repeat',
   backgroundPosition: 'top',
   backgroundSize: 'cover',
   zIndex: 111,
   overflow: 'hidden',
   color: theme.palette.text.primary,
   transition: 'all 250ms ease-in-out',
   backgroundImage: `linear-gradient(to bottom, rgba(${primarybg}, 0.96), rgba(${primarybg}, 0.96)), url(${bgimgurl})`,
   '&:hover': {
      width: sideNavWidth,
      '& .sidenavHoverShow': {
         display: 'block'
      },
      '& .compactNavItem': {
         width: '100%',
         maxWidth: '100%',
         '& .nav-bullet': {
            display: 'block'
         },
         '& .nav-bullet-text': {
            display: 'none'
         }
      }
   }
}))

const NavListBox = styled(Box)(() => ({
   height: '100%',
   display: 'flex',
   flexDirection: 'column'
}))

function Layout1Sidenav() {
   const theme = useTheme()
   const { settings } = useSettings()
   const { leftSidebar } = settings.layout1Settings
   const { mode, bgimgurl } = leftSidebar

   const getSidenavWidth = () => {
      switch (mode) {
         case 'compact':
            return sidenavCompactWidth
         default:
            return sideNavWidth
      }
   }
   const primaryRGB = convertHexToRGB(theme.palette.primary.main)

   return (
      <SidebarNavRoot
         bgimgurl={bgimgurl}
         primarybg={primaryRGB}
         width={getSidenavWidth()}
      >
         <NavListBox>
            <Brand>
               <Hidden smDown />
            </Brand>
            <Sidenav />
         </NavListBox>
      </SidebarNavRoot>
   )
}

export default React.memo(Layout1Sidenav)
