import {
   Avatar,
   Hidden,
   IconButton,
   MenuItem,
   useMediaQuery,
   Box,
   styled,
   useTheme
} from '@mui/material'
// import HomeIcon from '@mui/icons-material/Home';
// import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
// import SettingsIcon from '@mui/icons-material/Settings';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
// import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
// import WebAssetOutlinedIcon from '@mui/icons-material/WebAssetOutlined';
// import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { useDispatch } from 'react-redux'
// import { NotificationProvider } from '../contexts/NotificationContext';
import { useSnackbar } from 'notistack'
import { MatxMenu } from './index'
import { themeShadows } from '../themes/themeColors'
import useSettings from '../hooks/useSettings'
import { topBarHeight } from '../utils/constant'
import { Span } from './Typography'
// import NotificationBar from './NotificationBar';
// import ShoppingCart from './ShoppingCart';
import { setloggedin } from '../store/slices/auth'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
   color: theme.palette.text.primary
}))

const TopbarRoot = styled('div')(() => ({
   top: 0,
   zIndex: 96,
   transition: 'all 0.3s ease',
   boxShadow: themeShadows[8],
   height: topBarHeight
}))

const TopbarContainer = styled(Box)(({ theme }) => ({
   padding: '8px',
   paddingLeft: 18,
   paddingRight: 20,
   height: '100%',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'space-between',
   background: theme.palette.primary.main,
   [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      paddingRight: 16
   },
   [theme.breakpoints.down('xs')]: {
      paddingLeft: 14,
      paddingRight: 16
   }
}))

const UserMenu = styled(Box)(() => ({
   display: 'flex',
   alignItems: 'center',
   cursor: 'pointer',
   borderRadius: 24,
   padding: 4,
   '& span': { margin: '0 8px' }
}))

const StyledItem = styled(MenuItem)(({ theme }) => ({
   display: 'flex',
   alignItems: 'center',
   minWidth: 185,
   '& a': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none'
   },
   '& span': { marginRight: '10px', color: theme.palette.text.primary }
}))

// const IconBox = styled('div')(({ theme }) => ({
//   display: 'inherit',
//   [theme.breakpoints.down('md')]: { display: 'none !important' }
// }));

function Layout1Topbar() {
   const { enqueueSnackbar } = useSnackbar()
   const theme = useTheme()
   const { settings, updateSettings } = useSettings()
   const isMdScreen = useMediaQuery(theme.breakpoints.down('md'))
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const [userEmail, setUserEmail] = useState('')

   const textColor = theme.palette.text.primary

   useEffect(() => {
      const email = localStorage.getItem('email')
      setUserEmail(email)
   }, [])

   const updateSidebarMode = (sidebarSettings) => {
      updateSettings({
         layout1Settings: { leftSidebar: { ...sidebarSettings } }
      })
   }

   const handleSidebarToggle = () => {
      const { layout1Settings } = settings
      let mode
      if (isMdScreen) {
         mode =
            layout1Settings.leftSidebar.mode === 'close' ? 'mobile' : 'close'
      } else {
         mode = layout1Settings.leftSidebar.mode === 'full' ? 'close' : 'full'
      }
      updateSidebarMode({ mode })
   }

   const handleLogout = () => {
      localStorage.removeItem('isLoggedin')
      dispatch(setloggedin(false))
      enqueueSnackbar('User logged out', { variant: 'success' })
      navigate('/login')
   }

   return (
      <TopbarRoot>
         <TopbarContainer>
            <Box display="flex">
               <StyledIconButton onClick={handleSidebarToggle}>
                  <MenuIcon small />
               </StyledIconButton>

               {/* <IconBox>
            <StyledIconButton>
              <EmailOutlinedIcon small />
            </StyledIconButton>

            <StyledIconButton>
              <WebAssetOutlinedIcon small />
            </StyledIconButton>

            <StyledIconButton>
              <StarBorderOutlinedIcon small />
            </StyledIconButton>
          </IconBox> */}
            </Box>

            <Box display="flex" alignItems="center">
               {/* <MatxSearchBox />

          <NotificationProvider>
            <NotificationBar />
          </NotificationProvider>

          <ShoppingCart /> */}

               <MatxMenu
                  menuButton={
                     <UserMenu>
                        <Hidden xsDown>
                           <Span>
                              Hi <strong>{userEmail}</strong>
                           </Span>
                        </Hidden>
                        <Avatar src={null} sx={{ cursor: 'pointer' }}>
                           {userEmail.slice(0, 1).toUpperCase()}
                        </Avatar>
                     </UserMenu>
                  }
               >
                  {/* <StyledItem>
              <Link to="/dashboard">
                <HomeIcon small sx={{ color: textColor }} />
                <Span> Home </Span>
              </Link>
            </StyledItem> */}

                  {/* <StyledItem>
              <Link to="/page-layouts/user-profile">
                <PersonIcon small sx={{ color: textColor }} />
                <Span> Profile </Span>
              </Link>
            </StyledItem>

            <StyledItem>
              <SettingsIcon small sx={{ color: textColor }} />
              <Span> Settings </Span>
            </StyledItem> */}

                  <StyledItem onClick={handleLogout}>
                     <PowerSettingsNewIcon small sx={{ color: textColor }} />
                     <Span> Logout </Span>
                  </StyledItem>
               </MatxMenu>
            </Box>
         </TopbarContainer>
      </TopbarRoot>
   )
}

export default React.memo(Layout1Topbar)
