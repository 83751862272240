import { createTheme } from '@mui/material'

const lightTheme = createTheme({
   palette: {
      primary: {
         main: '#222a45'
      }
   }
})

export default lightTheme
