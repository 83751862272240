import React, { Suspense, lazy } from "react";

import { Navigate, useRoutes } from "react-router-dom";

import SuspenseLoader from "../components/SuspenseLoader";
import Layout from "../layouts";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const InventoryManagement = Loader(
  lazy(() => import("../pages/inventory-management"))
);

const NiloEffciencies = Loader(
  lazy(() => import("../pages/nilo-efficiencies"))
);

const AppWalletManager = Loader(
  lazy(() => import("../pages/app-wallet-manager"))
);

const BrushEventSettings = Loader(
  lazy(() => import("../pages/brush-events-settings"))
);

const BrushEventDetails = Loader(
  lazy(() => import("../pages/brush-event-details"))
);
const ItemDetails = Loader(lazy(() => import("../pages/item-details")));
const GearDetails = Loader(lazy(() => import("../pages/gear-details")));
const BrushingEvents = Loader(lazy(() => import("../pages/brushing-events")));
const NiloDetails = Loader(lazy(() => import("../pages/nilo-details")));
const BrushEvents = Loader(lazy(() => import("../pages/brush-events")));
const DeviceManager = Loader(lazy(() => import("../pages/device-manager")));
const UserDetails = Loader(lazy(() => import("../pages/user-details")));
const NiloManager = Loader(lazy(() => import("../pages/nilo-manager")));
const GearManager = Loader(lazy(() => import("../pages/gear-manager")));
const ItemManager = Loader(lazy(() => import("../pages/item-manager")));
const PltCalculation = Loader(lazy(() => import("../pages/plt-calculation")));
const PLTDistribution = Loader(lazy(() => import("../pages/plt-distribution")));
const BrushHistory = Loader(lazy(() => import("../pages/brush-history")));
const BrushingHistory = Loader(lazy(() => import("../pages/brushing-history")));
const Nilos = Loader(lazy(() => import("../pages/nilos")));
const Login = Loader(lazy(() => import("../pages/login")));
//const Register = Loader(lazy(() => import('../pages/register')));
const ForgotPassword = Loader(lazy(() => import("../pages/forgotPassword")));
const Dashboard = Loader(lazy(() => import("../pages/dashboard")));
const User = Loader(lazy(() => import("../pages/user")));
const Config = Loader(lazy(() => import("../pages/config")));
const AssetsManagement = Loader(lazy(() => import("../pages/Assets")));
const DeviceManagement = Loader(lazy(() => import("../pages/devices")));
const DeviceDetails = Loader(lazy(() => import("../pages/device-details")));
const Transactions = Loader(lazy(() => import("../pages/transactions")));
const Brushes = Loader(lazy(() => import("../pages/brushes")));
const New = Loader(lazy(() => import("../pages/new")));
const NLTTransfer = Loader(lazy(() => import("../pages/NLTTransfer")));
const FirmwareUpgrade = Loader(lazy(() => import("../pages/firmware-upgrade")));
const AppUpdate = Loader(lazy(() => import("../pages/app-update")));
const HpDecayLogs = Loader(lazy(() => import("../pages/hp-decay-logs")));
const ExternalTransfers = Loader(
  lazy(() => import("../pages/external-transfers"))
);
const OrdersLogs = Loader(lazy(() => import("../pages/order-logs")));

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <Layout /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        index: true,
        element: <RedirectionWrapper to="/users" />,
      },
      {
        path: '/dashboard',
        element: <Dashboard />
      },
      {
        path: "/users",
        element: <User />,
      },
      {
        path: "/config",
        element: <Config />,
      },
      {
        path: "/blog",
        element: <Dashboard />,
      },
      {
        path: "/nilos",
        element: <Nilos />,
      },
      {
        path: "/brushing-history/:userId",
        element: <BrushingHistory />,
      },
      {
        path: "/brush-history",
        element: <BrushHistory />,
      },
      {
        path: "/plt-calculation/:userId",
        element: <PltCalculation />,
      },
      {
        path: "/item-manager",
        element: <ItemManager />,
      },
      {
        path: "/gear-manager",
        element: <GearManager />,
      },
      {
        path: "/nilo-manager",
        element: <NiloManager />,
      },
      {
        path: "/inventory-management",
        element: <InventoryManagement />,
      },
      {
        path: "/epoch",
        element: <PLTDistribution />,
      },
      {
        path: "/appupdate",
        element: <AppUpdate />,
      },
      {
        path: "/nilo-effciencies",
        element: <NiloEffciencies />,
      },
      {
        path: "/app-wallet-manager",
        element: <AppWalletManager />,
      },
      {
        path: "/device-manager",
        element: <DeviceManager />,
      },
      {
        path: "/brush-events",
        element: <BrushEvents />,
      },
      {
        path: "/user-details/:userId",
        element: <UserDetails />,
      },
      {
        path: "/brushing-events",
        element: <BrushingEvents />,
      },
      {
        path: "/brush-event-settings",
        element: <BrushEventSettings />,
      },
      {
        path: "/nilo-assets",
        element: <AssetsManagement />,
      },
      {
        path: "/devices",
        element: <DeviceManagement />,
      },
      {
        path: "/device-details",
        element: <DeviceDetails />,
      },
      {
        path: "/transactions",
        element: <Transactions />,
      },
      {
        path: "/brushes",
        element: <Brushes />,
      },
      {
        path: "/nilo-details/:niloId",
        element: <NiloDetails />,
      },
      {
        path: "/gear-details/:gearId",
        element: <GearDetails />,
      },
      {
        path: "/item-details/:itemId",
        element: <ItemDetails />,
      },
      {
        path: "/show-item-details/:showitemId",
        element: <ItemDetails />,
      },

      {
        path: "/brush-event-details/:deviceActivityId",
        element: <BrushEventDetails />,
      },
      {
        path: "/new",
        element: <New />,
      },
      {
        path: "/NLT-Transfer",
        element: <NLTTransfer />,
      },
      {
        path: "/firmware-upgrade",
        element: <FirmwareUpgrade />,
      },
      {
        path: "/hp-decay-logs",
        element: <HpDecayLogs />,
      },
      {
        path: "/external-transfers",
        element: <ExternalTransfers />,
      },
      {
        path: "/order-logs",
        element: <OrdersLogs />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedIn ? <Login /> : <RedirectionWrapper to="/users" />,
  },
  // {
  //    path: '/register',
  //    element: !isLoggedIn ? <Register /> : <RedirectionWrapper to="/users" />
  // },
  {
    path: "/forgot-password",
    element: !isLoggedIn ? (
      <ForgotPassword />
    ) : (
      <RedirectionWrapper to="/users" />
    ),
  },
  {
    path: "*",
    element: <RedirectionWrapper to="/" />,
  },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
