/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from 'react'

import { merge } from 'lodash'

import { MatxLayoutSettings } from '../layouts/settings'

const SettingsContext = createContext({
   settings: MatxLayoutSettings,
   updateSettings: () => {}
})

export function SettingsProvider({ settings, children }) {
   const [currentSettings, setCurrentSettings] = useState(
      settings || MatxLayoutSettings
   )

   const handleUpdateSettings = (update = {}) => {
      const marged = merge({}, currentSettings, update)
      setCurrentSettings(marged)
   }

   return (
      <SettingsContext.Provider
         value={{
            settings: currentSettings,
            updateSettings: handleUpdateSettings
         }}
      >
         {children}
      </SettingsContext.Provider>
   )
}

export default SettingsContext
