import { styled } from '@mui/material'
import React, { Fragment } from 'react'
import Scrollbar from 'react-perfect-scrollbar'
import { navigations } from '../navigations/navigations'
import MatxVerticalNav from './MatxVerticalNav'
import useSettings from '../hooks/useSettings'

const StyledScrollBar = styled(Scrollbar)(() => ({
   paddingLeft: '1rem',
   paddingRight: '1rem',
   position: 'relative'
}))

const SideNavMobile = styled('div')(({ theme }) => ({
   position: 'fixed',
   top: 0,
   left: 0,
   bottom: 0,
   right: 0,
   width: '100vw',
   background: 'rgba(0, 0, 0, 0.54)',
   zIndex: -1,
   [theme.breakpoints.up('lg')]: { display: 'none' }
}))

function Sidenav({ children }) {
   const { settings, updateSettings } = useSettings()

   const updateSidebarMode = (sidebarSettings) => {
      const activeLayoutSettingsName = `${settings.activeLayout}Settings`
      const activeLayoutSettings = settings[activeLayoutSettingsName]

      updateSettings({
         ...settings,
         [activeLayoutSettingsName]: {
            ...activeLayoutSettings,
            leftSidebar: {
               ...activeLayoutSettings.leftSidebar,
               ...sidebarSettings
            }
         }
      })
   }

   return (
      <>
         <StyledScrollBar options={{ suppressScrollX: true }}>
            {children}
            <MatxVerticalNav items={navigations} />
         </StyledScrollBar>

         <SideNavMobile onClick={() => updateSidebarMode({ mode: 'close' })} />
      </>
   )
}

export default Sidenav
