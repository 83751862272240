import { ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import lightTheme from "./themes/lightTheme";
import "react-perfect-scrollbar/dist/css/styles.css";
import { SettingsProvider } from "./contexts/SettingsContext";
import Routes from "./routes";
import { setloggedin, setToken } from "./store/slices/auth";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(setToken(JSON.parse(localStorage.getItem("token"))));
    }
    if (localStorage.getItem("isLoggedin")) {
      dispatch(setloggedin(JSON.parse(localStorage.getItem("isLoggedin"))));
    }
  }, []);

  const { isLoggedin } = useSelector((state) => state.auth);

  const isLoggedIn =
    isLoggedin || JSON.parse(localStorage.getItem("isLoggedIn"));

  return (
    <ThemeProvider theme={lightTheme}>
      <SettingsProvider>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={3}
          hideIconVariant
          dense
          autoHideDuration={1000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <BrowserRouter>
            <Routes isLoggedIn={isLoggedIn} />
          </BrowserRouter>
        </SnackbarProvider>
      </SettingsProvider>
    </ThemeProvider>
  );
}

export default App;
