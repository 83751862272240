import { IconButton, styled, useTheme } from '@mui/material'
import React, { useState } from 'react'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { topBarHeight } from '../utils/constant'

const SearchContainer = styled('div')(({ theme }) => ({
   position: 'absolute',
   top: 0,
   left: 0,
   zIndex: 9,
   width: '100%',
   display: 'flex',
   alignItems: 'center',
   height: topBarHeight,
   background: theme.palette.primary.main,
   color: theme.palette.text.primary,
   '&::placeholder': {
      color: theme.palette.text.primary
   }
}))

const SearchInput = styled('input')(({ theme }) => ({
   width: '100%',
   border: 'none',
   outline: 'none',
   fontSize: '1rem',
   paddingLeft: '20px',
   height: 'calc(100% - 5px)',
   background: theme.palette.primary.main,
   color: theme.palette.text.primary,
   '&::placeholder': { color: theme.palette.text.primary }
}))

function MatxSearchBox() {
   const [open, setOpen] = useState(false)
   const toggle = () => {
      setOpen(!open)
   }

   const { palette } = useTheme()
   const textColor = palette.text.primary

   return (
      <>
         {!open && (
            <IconButton onClick={toggle}>
               <SearchOutlinedIcon sx={{ color: textColor }} small />
               {/* <Icon sx={{ color: textColor }}>search</Icon> */}
            </IconButton>
         )}

         {open && (
            <SearchContainer>
               <SearchInput
                  type="text"
                  placeholder="Search here..."
                  autoFocus
               />
               <IconButton
                  onClick={toggle}
                  sx={{ mx: 2, verticalAlign: 'middle' }}
               >
                  <CloseIcon sx={{ color: textColor }} small />
               </IconButton>
            </SearchContainer>
         )}
      </>
   )
}

export default MatxSearchBox
