import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'

import AuthService from '../../services/auth.service'

export const verify = createAsyncThunk(
   'auth/verify',
   async (requestObj, thunkAPI) => {
      try {
         const response = await AuthService.verify(requestObj)
         const token = get(response, 'token', null)
         return token
      } catch (error) {
         return thunkAPI.rejectWithValue(
            get(error, 'response.data.apierror', error)
         )
      }
   }
)

export const getUserInfo = createAsyncThunk(
   'auth/userInfo',
   async (thunkAPI) => {
      try {
         const response = await AuthService.getUserInfo()
         const data = get(response, 'data.apiresponse.data')
         return data
      } catch (error) {
         localStorage.removeItem('token')
         localStorage.clear()
         window.location = '/login'
         return thunkAPI.rejectWithValue(
            get(error, 'response.data.apierror', error)
         )
      }
   }
)

export const updateUser = createAsyncThunk(
   'auth/updateUser',
   async (requestObj, thunkAPI) => {
      try {
         const response = await AuthService.userUpdate(requestObj)
         const data = get(response, 'data.apiresponse.data')
         return data
      } catch (error) {
         return thunkAPI.rejectWithValue(
            get(error, 'response.data.apierror', error)
         )
      }
   }
)

export const logout = createAsyncThunk('auth/logout', () => {
   AuthService.logout()
})

const initialState = {
   loading: false,
   token: null,
   error: false,
   signUp: false,
   user: null,
   tour: 0,
   isLoggedin: !!JSON.parse(localStorage.getItem('isLoggedin'))
}

const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      setloggedin: (state, action) => {
         state.isLoggedin = action.payload
      },
      setToken: (state, action) => {
         state.token = action.payload
      },
      setSignUp: (state, action) => {
         state.signUp = action.payload
      },
      setUser: (state, action) => {
         state.user = action.payload
         state.token = get(action.payload, 'token', null)
         localStorage.setItem(
            'token',
            JSON.stringify(get(action.payload, 'token', null))
         )
      },
      setTour: (state, action) => {
         state.tour = action.payload
         localStorage.setItem('tour', JSON.stringify(action.payload))
      }
   },
   extraReducers: {
      [verify.pending]: (state) => {
         state.loading = true
      },
      [verify.fulfilled]: (state, action) => {
         state.token = action.payload
         state.loading = false
      },
      [verify.rejected]: (state) => {
         state.error = true
         state.loading = false
      },
      [getUserInfo.pending]: (state) => {
         state.loading = true
      },
      [getUserInfo.fulfilled]: (state, action) => {
         state.user = action.payload
         state.loading = false
      },
      [getUserInfo.rejected]: (state) => {
         state.error = true
         state.loading = false
      },
      [updateUser.pending]: (state) => {
         state.loading = true
      },
      [updateUser.fulfilled]: (state, action) => {
         state.user = action.payload
         state.loading = false
      },
      [updateUser.rejected]: (state) => {
         state.error = true
         state.loading = false
      },
      [logout.fulfilled]: (state) => {
         state.token = null
      }
   }
})

const { reducer, actions } = authSlice

export const { setToken, setSignUp, setUser, setTour, setloggedin } = actions
export default reducer
